import {
  Text,
  Card,
  Box,
  Title,
  ActionIcon,
  Space,
  Collapse,
  MultiSelect,
  Input,
  Button,
  Center,
  Flex,
  Grid,
} from '@mantine/core'
import { IconRefresh, IconFilter, IconSearch, IconUpload, IconSparkles } from '@tabler/icons-react'
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { useOrders } from 'src/providers/Orders'
import { useDistributor } from 'src/providers/Distributor'
import styles from './SupplyBySKU.module.css'
import { useRef } from 'react'
import { Demand } from 'src/types'
import { useErrorBoundary } from 'react-error-boundary'
import { useMutation } from '@tanstack/react-query'
import { updateDemands } from 'src/requests/firebase/demands/updateDemands'
import { useCategories } from 'src/hooks/useCategories'
import { useFilterArray } from 'src/hooks/useFilterArray'
import { useBulkCheckbox } from 'src/hooks/useBulkCheckbox'
import { GenerateOrderWithAIModal } from './Modals/GenerateOrderWithAIModal'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { SupplyBySKUTable } from './Tables/SupplyBySKU'
import { UploadDemandModal } from './Modals/UploadDemandModal'
import useSKUsWithOrderData from 'src/hooks/useSKUs'
import { DatePickerInput } from '@mantine/dates'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useProducers } from 'src/hooks/useProducers'
import { useDeliveryDate } from 'src/hooks/useDeliveryDate'
import { useCalculateSKUsMetrics } from './hooks/useCalculateSKUsMetrics'

export default function SupplyBySKU() {
  const { isFetching, setShouldRefetch: setShouldRefetchOrders } = useOrders()
  const { distributor } = useDistributor()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const skusWithAggregatedData = useSKUsWithOrderData()
  const cardPadding = isMobile ? 'md' : 'xl'
  const [isDemandModalOpen, { open: openDemandModal, close: closeDemandModal }] = useDisclosure(false)
  const [isGenerateOrderModalOpen, { open: openGenerateOrderModal, close: closeGenerateOrderModal }] =
    useDisclosure(false)
  const demandMutation = useMutation({
    mutationFn: updateDemands,
  })
  const { showBoundary } = useErrorBoundary()
  const [scroll] = useWindowScroll()
  const cardRef = useRef<HTMLDivElement>(null)

  // Filter
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(true)

  const { setQueryParams, getQueryParams } = useQueryParams()

  const categories = useCategories()

  const search = getQueryParams('search')
  const selectedCategories = getQueryParams('categories').split(',').filter(Boolean)
  const selectedProducers = getQueryParams('producers').split(',').filter(Boolean)
  const isoDeliveryDate = getQueryParams('date')
  const deliveryDate = useDeliveryDate(isoDeliveryDate)
  const { labelValueProducers } = useProducers()

  const filteredList = useFilterArray(skusWithAggregatedData, [
    {
      type: 'single',
      keys: ['Código', 'SKU'],
      value: search,
    },
    {
      type: 'multiple',
      keys: ['Categoria'],
      value: selectedCategories,
    },
    {
      type: 'single-date',
      keys: ['deliveryDates'],
      value: deliveryDate.utcDate,
    },
    {
      type: 'multiple',
      keys: ['producersPhones'],
      value: selectedProducers,
    },
  ])

  const listWithMetrics = useCalculateSKUsMetrics(filteredList, {
    deliveryDate: deliveryDate.utcDate,
    selectedProducers,
  })

  function changeSearchParams(name: string, value?: string) {
    setQueryParams({
      [name]: value,
    })
  }

  function refreshAll() {
    setShouldRefetchOrders(true)
  }

  // Selection
  const {
    isCheckboxChecked,
    onIndividualCheckboxChange,
    onMultipleCheckboxChange,
    allOptionsSelected,
    someOptionsSelected,
    anyOptionSelected,
    selectedOptions,
  } = useBulkCheckbox(filteredList.map((product) => String(product['SKU'])))

  async function onConfirmUploadDemand(demands: Demand[]) {
    const notification = pendingNotificationFactory({
      pending: {
        title: 'Criando demanda...',
        message: 'Isso não deve demorar muito',
      },
      error: {
        title: 'Falha ao criar demanda',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      },
      success: {
        title: 'Demanda criada com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
      },
    })

    try {
      await demandMutation.mutateAsync({
        demands: demands,
        distributorId: distributor!.distributorId,
      })
      notification.success()
      closeDemandModal()
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error.message)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card} ref={cardRef}>
      <GenerateOrderWithAIModal
        opened={isGenerateOrderModalOpen}
        selectedSKUs={selectedOptions}
        onClose={closeGenerateOrderModal}
      />
      <UploadDemandModal opened={isDemandModalOpen} onClose={closeDemandModal} onConfirm={onConfirmUploadDemand} />
      <Box className={styles.fixed_table_name} w={cardRef.current?.offsetWidth ?? 0} top={scroll.y > 0 ? 60 : 80}>
        <Box className={styles.header__title}>
          <Title order={3}>Ofertas</Title>

          <Box display={scroll.y > 0 ? 'none' : 'flex'} className={styles.header__actions}>
            <ActionIcon
              onClick={toggleFilter}
              loaderProps={{ type: 'dots' }}
              variant={filterOpened ? 'light' : 'subtle'}
              size="lg"
              title="Filtro"
            >
              <IconFilter size={20} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              onClick={openDemandModal}
              loaderProps={{ type: 'dots' }}
              variant="subtle"
              title="Criar demanda"
            >
              <IconUpload size={20} />
            </ActionIcon>
            <ActionIcon
              onClick={refreshAll}
              loading={isFetching}
              loaderProps={{ type: 'dots' }}
              variant="filled"
              title="Recarregar"
            >
              <IconRefresh size={20} />
            </ActionIcon>
            <Button to="/ofertas/sku/cadastrar" component={Link} size="compact-md">
              Cadastrar SKU
            </Button>

            <ActionIcon
              onClick={openGenerateOrderModal}
              loaderProps={{ type: 'dots' }}
              variant="gradient"
              title="Gerar pedido com AI"
            >
              <IconSparkles size={20} />
            </ActionIcon>
          </Box>
        </Box>
      </Box>
      <Box mt={30} className={styles.header}>
        <Collapse in={filterOpened}>
          <Grid mt={8}>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Input.Wrapper label="Pesquisar">
                <Input
                  id="search"
                  name="search"
                  placeholder="Pesquise por código ou SKU"
                  leftSection={<IconSearch size={16} />}
                  value={search}
                  onChange={(e) => {
                    changeSearchParams(e.target.name, e.target.value)
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <DatePickerInput
                label="Data de entrega"
                placeholder="Selecione uma data"
                locale="pt-br"
                valueFormat="DD/MMM"
                clearable
                value={deliveryDate.zonedDate}
                onChange={(date) => {
                  changeSearchParams('date', date?.toISOString())
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <MultiSelect
                name="category"
                placeholder="Escolha uma ou mais categorias"
                label="Categorias"
                data={categories}
                searchable
                clearable
                checkIconPosition="right"
                value={selectedCategories}
                onChange={(values) => {
                  changeSearchParams('categories', values.join(','))
                }}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <MultiSelect
                name="producers"
                placeholder="Escolha um ou mais produtores"
                label="Produtores"
                data={labelValueProducers}
                searchable
                clearable
                checkIconPosition="right"
                value={selectedProducers}
                onChange={(values) => {
                  changeSearchParams('producers', values.join(','))
                }}
              />
            </Grid.Col>
          </Grid>
        </Collapse>

        <Collapse in={anyOptionSelected}>
          <Space h="lg" />
          <Flex justify="center">
            <Button
              rightSection={<IconSparkles size={20} />}
              loaderProps={{ type: 'dots' }}
              onClick={openGenerateOrderModal}
              variant="gradient"
            >
              Gerar sugestão de pedido AI para os itens selecionados
            </Button>
          </Flex>
        </Collapse>
      </Box>
      <Space h="lg" />
      {!skusWithAggregatedData?.length ? (
        <Center px={25}>
          <Text>Você ainda não cadastrou SKUs</Text>
        </Center>
      ) : (
        <SupplyBySKUTable
          allOptionsSelected={allOptionsSelected}
          isCheckboxChecked={isCheckboxChecked}
          onIndividualCheckboxChange={onIndividualCheckboxChange}
          onMultipleCheckboxChange={onMultipleCheckboxChange}
          someOptionsSelected={someOptionsSelected}
          items={listWithMetrics}
        />
      )}
    </Card>
  )
}
