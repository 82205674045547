import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  InputBase,
  InputDescription,
  MultiSelect,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { debounce } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import IntlCurrencyInput from 'react-intl-currency-input'
import { useParams } from 'react-router-dom'
import { brazilianStatesArray } from 'src/constants/address'
import { DEFAULT_PRODUCER_RECEIVING_DAYS } from 'src/constants/producers'
import { useSKUs } from 'src/hooks/useSKUs'
import { useDistributor } from 'src/providers/Distributor'
import { searchZipCode } from 'src/services/postmonApiService'
import { ProducerReceivingDays, TCreateOrUpdateProducerForm } from 'src/types'
import { addNonDigit, removeNonDigit } from 'src/utils/parseBrazilianPhones'
import * as yup from 'yup'

const dayNumberToName: Record<ProducerReceivingDays, string> = {
  '0': 'Domingo',
  '1': 'Segunda',
  '2': 'Terça',
  '3': 'Quarta',
  '4': 'Quinta',
  '5': 'Sexta',
  '6': 'Sábado',
}

const producerSchema = yup.object().shape({
  phone: yup
    .string()
    .required('O campo "WhatsApp" é obrigatório.')
    .matches(/^\+?55 \d{2} \d{8,9}$/, {
      excludeEmptyString: true,
      message: 'O campo "WhatsApp" precisa ser um número válido',
    })
    .test('duplicated_phone', 'Já existe um produtor com esse número', (value, { options }) => {
      if (!value) return true

      const currentProducerPhone: string | undefined = options.context!.currentProducerPhone
      const currentWhatsappNumbers: string[] = options.context!.currentWhatsappNumbers
      const isSame = currentWhatsappNumbers
        .filter((phone) => phone !== currentProducerPhone)
        .includes(removeNonDigit(value))

      if (isSame) {
        return false
      }

      return true
    }),
  code: yup
    .string()
    .required('O campo "Código" é obrigatório.')
    .min(3, 'O campo "Código" deve ter pelo menos 3 caracteres.')
    .test('duplicated_code', 'Já existe um produtor com esse código', (value, { options }) => {
      if (!value) return true

      const isEditing: boolean = options.context!.isEditing
      const currentProducersCodes: string[] = options.context!.currentProducersCodes
      const isSame = currentProducersCodes.includes(value.toUpperCase())

      if (!isEditing && isSame) {
        return false
      }

      return true
    }),
  name: yup.string().required('O campo "Nome" é obrigatório.'),
  taxpayerIdentificationNumber: yup.string().test('len', 'O campo "CPF/CNPJ" é inválido', (value) => {
    if (!value) return true

    const unmaskedValue = value.replace(/[^\d]/g, '')
    return unmaskedValue.length === 11 || unmaskedValue.length === 14
  }),
  address: yup.object().shape({
    zipCode: yup.string().optional(),
    state: yup.string().nullable().optional(),
    city: yup.string().optional(),
    neighborhood: yup.string().optional(),
    street: yup.string().optional(),
    number: yup.string().optional(),
    complement: yup.string().optional(),
  }),
  observations: yup.string().optional(),
  skuCodesReference: yup.array(yup.string().required()).required(),
  fixedPrices: yup
    .array(
      yup.object().shape({
        sku: yup.string().required(),
        fixedPrice: yup.number().required(),
      }),
    )
    .required(),
  rejectionRate: yup.number().required(),
  hasInfiniteStock: yup.boolean().required(),
  receivingDays: yup.array(yup.string().required().oneOf(DEFAULT_PRODUCER_RECEIVING_DAYS)).required(),
  minimumOrderPrice: yup.number().required(),
})

type Producer = yup.InferType<typeof producerSchema>

type ProducerFormProps = {
  onSubmit: (data: TCreateOrUpdateProducerForm) => Promise<void>
  defaultValues?: TCreateOrUpdateProducerForm
}

const ProducerForm: React.FC<ProducerFormProps> = ({ onSubmit, defaultValues }) => {
  const { phone } = useParams()
  const { distributor } = useDistributor()
  const [isZipCodeLoading, setIsZipCodeLoading] = useState(false)

  const currentWhatsappNumbers = useMemo(() => {
    return distributor?.producers.map((producer) => producer.phone) ?? []
  }, [distributor?.producers])

  const currentProducer = useMemo(() => {
    return distributor?.producers.find((producer) => producer.phone === phone)
  }, [distributor?.producers, phone])

  const currentProducersCodes = useMemo(() => {
    return distributor?.producers.map((producer) => producer.code) ?? []
  }, [distributor?.producers])

  const { register, handleSubmit, formState, control, setValue } = useForm<Producer>({
    resolver: yupResolver(producerSchema),
    defaultValues: {
      code: '',
      name: '',
      phone: '',
      fixedPrices: [],
      hasInfiniteStock: false,
      skuCodesReference: [],
      taxpayerIdentificationNumber: '',
      address: {
        zipCode: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
      },
      observations: '',
      receivingDays: DEFAULT_PRODUCER_RECEIVING_DAYS,
      rejectionRate: 0,
      minimumOrderPrice: 0,
      ...(defaultValues && {
        ...defaultValues,
        phone: addNonDigit(defaultValues.phone),
      }),
    },
    context: {
      currentWhatsappNumbers,
      currentProducersCodes,
      isEditing: !!defaultValues,
      currentProducerPhone: currentProducer?.phone,
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'fixedPrices',
    control: control,
  })

  const { labelValueSKUs } = useSKUs()

  const onSubmitForm = useCallback(
    async (data: Producer) => {
      const filteredData = {
        ...data,
        phone: removeNonDigit(data.phone),
        fixedPrices: data.fixedPrices.filter((item) => !!item.fixedPrice),
      }

      await onSubmit(filteredData)
    },
    [onSubmit],
  )

  const debouncedRequest = useCallback(
    debounce(async (value: string) => {
      try {
        setIsZipCodeLoading(true)
        const res = await searchZipCode(value)
        const { cep, estado, cidade, bairro, logradouro } = res
        setValue('address.zipCode', cep)
        setValue('address.state', estado)
        setValue('address.city', cidade)
        setValue('address.neighborhood', bairro)
        setValue('address.street', logradouro)
      } catch (error) {
        notifications.show({
          title: 'Erro',
          message: 'CEP inválido ou inexistente',
          color: 'red',
        })
      } finally {
        setIsZipCodeLoading(false)
      }
    }, 800),
    [],
  )

  const { errors } = formState

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box mb="sm">
        <Text fw={500}>Dados básicos</Text>
      </Box>
      <Grid grow>
        <Grid.Col span={{ base: 12, xs: 6, lg: 4 }}>
          <TextInput
            disabled={!!defaultValues || formState.isSubmitting}
            label="Código"
            {...register('code', { required: true })}
            error={errors.code?.message}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 6 }}>
          <TextInput label="Nome" {...register('name', { required: true })} error={errors.name?.message} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => {
              return (
                <InputBase
                  component={IMaskInput}
                  label="WhatsApp"
                  mask="+55 00 000000000"
                  onPaste={(e) => {
                    e.preventDefault()
                    setValue('phone', addNonDigit(removeNonDigit(e.clipboardData.getData('text'))))
                  }}
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 3, xs: 3, lg: 3 }}>
          <Controller
            control={control}
            name="taxpayerIdentificationNumber"
            render={({ field, fieldState }) => (
              <InputBase
                component={IMaskInput}
                label="CPF/CNPJ"
                mask={[
                  {
                    mask: '000.000.000-00',
                    maxLength: 14,
                  },
                  {
                    mask: '00.000.000/0000-00',
                    maxLength: 18,
                  },
                ]}
                onAccept={(value) => {
                  field.onChange(value)
                }}
                unmask={true}
                value={field.value}
                error={fieldState.error?.message}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Grid grow>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <Controller
            control={control}
            name="address.zipCode"
            render={({ field }) => (
              <InputBase
                component={IMaskInput}
                label="CEP"
                mask="00000-000"
                onAccept={(value) => {
                  if (field.value !== value) {
                    field.onChange(value)
                  }
                  if (value.replace('-', '').length === 8 && field.value !== value) {
                    debouncedRequest(value)
                  }
                }}
                unmask={true}
                value={field.value}
                error={errors.address?.zipCode?.message}
                disabled={isZipCodeLoading}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <Controller
            name="address.state"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                label="Estado"
                withCheckIcon
                allowDeselect={true}
                checkIconPosition="right"
                placeholder="Selecione o estado"
                data={brazilianStatesArray}
                {...field}
                error={fieldState.error?.message}
                disabled={isZipCodeLoading}
                searchable={true}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <TextInput
            label="Cidade"
            {...register('address.city')}
            error={errors.address?.city?.message}
            disabled={isZipCodeLoading}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <TextInput
            label="Bairro"
            {...register('address.neighborhood')}
            error={errors.address?.neighborhood?.message}
            disabled={isZipCodeLoading}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <TextInput
            label="Rua"
            {...register('address.street')}
            error={errors.address?.street?.message}
            disabled={isZipCodeLoading}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <TextInput label="Número" {...register('address.number')} error={errors.address?.number?.message} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, lg: 3 }}>
          <TextInput
            label="Complemento"
            {...register('address.complement')}
            error={errors.address?.complement?.message}
          />
        </Grid.Col>
      </Grid>
      <Grid grow>
        <Grid.Col span={12}>
          <Textarea
            label="Observações"
            {...register('observations')}
            error={errors.observations?.message}
            maxLength={200}
          />
        </Grid.Col>
      </Grid>

      <Divider mt="xl" mb="lg" />
      <Box mb="sm">
        <Text fw={500}>Produtos</Text>
      </Box>

      <Grid grow>
        <Grid.Col span={12}>
          <Controller
            name="skuCodesReference"
            control={control}
            render={({ field, fieldState }) => (
              <MultiSelect
                label="SKUs"
                data={labelValueSKUs}
                searchable
                clearable
                onRemove={(value) => {
                  const fieldArrIdx = fields.findIndex((item) => item.sku === value)
                  remove(fieldArrIdx)
                }}
                onOptionSubmit={(value) => {
                  append({
                    sku: value,
                    fixedPrice: 0,
                  })
                }}
                checkIconPosition="right"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col>
          <Checkbox {...register('hasInfiniteStock')} label="Tem volume infinito" />
        </Grid.Col>
      </Grid>

      <Divider mt="xl" mb="lg" />
      <Box mb="sm">
        <Text fw={500}>Entrega</Text>
      </Box>

      <Grid>
        <Grid.Col span={12}>
          <Controller
            control={control}
            name="receivingDays"
            render={({ field, fieldState }) => (
              <Checkbox.Group label="Dias de recebimento:" {...field} error={fieldState.error?.message}>
                {DEFAULT_PRODUCER_RECEIVING_DAYS.map((day) => {
                  return (
                    <Box key={day} display="inline-block">
                      <Checkbox mr="md" label={`${dayNumberToName[day]}`} value={day} />
                    </Box>
                  )
                })}
              </Checkbox.Group>
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 4, lg: 3 }}>
          <Controller
            name="rejectionRate"
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <NumberInput
                label="Taxa de devolução"
                placeholder="Porcentagem"
                hideControls
                clampBehavior="strict"
                max={100}
                min={0}
                suffix="%"
                error={fieldState.error?.message}
                onChange={(val) => onChange(val || 0)}
                {...field}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 4, lg: 3 }}>
          <Controller
            control={control}
            // todo: define
            name="minimumOrderPrice"
            render={({ field, fieldState }) => (
              <InputBase
                label="Pedido mínimo"
                component={IntlCurrencyInput}
                currency="BRL"
                defaultValue={field.value ?? 0}
                value={field.value ?? 0}
                max={Number.MAX_SAFE_INTEGER}
                onChange={(_, rawValue: number) => {
                  return field.onChange(rawValue)
                }}
                error={fieldState.error?.message}
                config={{
                  locale: 'pt-BR',
                  formats: {
                    number: {
                      BRL: {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    },
                  },
                }}
              />
            )}
          />
        </Grid.Col>
      </Grid>

      <Divider mt="xl" mb="lg" />
      <Box mb="sm">
        <Text fw={500}>Preços fixos</Text>
        <InputDescription>Itens com valor R$ 0,00 (zero) não serão considerados.</InputDescription>
      </Box>

      <Stack mih="100">
        {fields.map((item, idx) => {
          const currentSkuWithName = labelValueSKUs.find((s) => s.value === item.sku)

          return (
            <Flex key={item.id} gap="sm">
              <Controller
                name={`fixedPrices.${idx}.sku`}
                control={control}
                defaultValue={currentSkuWithName?.value}
                render={({ field, fieldState }) => (
                  <Select data={[currentSkuWithName!]} readOnly disabled error={fieldState.error?.message} {...field} />
                )}
              />
              <Controller
                control={control}
                name={`fixedPrices.${idx}.fixedPrice`}
                render={({ field, fieldState }) => (
                  <InputBase
                    component={IntlCurrencyInput}
                    currency="BRL"
                    defaultValue={field.value ?? 0}
                    value={field.value ?? 0}
                    max={Number.MAX_SAFE_INTEGER}
                    onChange={(_, rawValue: number) => {
                      return field.onChange(rawValue)
                    }}
                    error={fieldState.error?.message}
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      },
                    }}
                  />
                )}
              />
            </Flex>
          )
        })}
      </Stack>

      <Button mt="xl" type="submit" loading={formState.isSubmitting}>
        Salvar
      </Button>
    </form>
  )
}

export default ProducerForm
