import { Alert, Box, Button, Flex, Group, Modal, Table, Text, Tooltip } from '@mantine/core'
import { Dropzone, FileWithPath } from '@mantine/dropzone'
import { IconFileTypeCsv, IconUpload, IconX } from '@tabler/icons-react'
import { parse as csvParse } from 'csv-parse/sync'
import { isBefore, isValid, parse, startOfDay } from 'date-fns'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useDistributor } from 'src/providers/Distributor'
import uploadCSV from 'src/requests/uploadCsv'
import { OrderImport, Producer, SKU } from 'src/types'

type ImportSentOrdersModalProps = {
  opened: boolean
  title: string
  data?: OrderImport[]
  onClose?: () => void
  onLoadData?: (data: OrderImport[]) => void
  onConfirm: (value: boolean) => void
}

export function ImportFromCSVModal(props: ImportSentOrdersModalProps) {
  const [error, setError] = useState('')
  const { distributor } = useDistributor()
  const fileRef = useRef<FileWithPath>()

  const handleClose = useCallback(() => {
    props.onLoadData?.([])
    props.onClose?.()
  }, [props])

  useCallback(() => {
    if (!props.opened) {
      props.onClose
    }
  }, [props.opened])

  function closeModal() {
    props.onLoadData?.([])
    props.onClose?.()
  }

  const sanitizedSheetData = useMemo(() => {
    if (!props.data) return []
    const uniqueSkus: Map<string, boolean> = new Map();

    return props.data.map((item) => {
      const producer = distributor?.producers.find((p) => p.code === item.producerCode)
      const sku = distributor?.skus.find((p) => p.SKU === item.sku)

      return sanitizeSheetData(item, sku, producer, uniqueSkus)
    })
  }, [distributor?.producers, distributor?.skus, props.data])

  const shouldDisableButtons = useMemo(() => {
    return sanitizedSheetData.some(
      (item) =>
        !item.sku.found || !item.producer.found || !item.desiredQty || !item.price || !item.deliveryDate.isValid || item.sku.isRepeated,
    )
  }, [sanitizedSheetData])

  function handleConfirm(send: boolean) {
    props.onConfirm(send)
    const formData = new FormData()

    if (fileRef.current) {
      formData.append('file', fileRef.current)
    }

    uploadCSV(formData)
  }

  return (
    <Modal opened={props.opened} onClose={handleClose} title={<Text fw={500}>{props.title}</Text>} size={'xl'}>
      <Box>
        <Dropzone
          onDrop={(files) => {
            fileRef.current = files[0]
            handleFileUpload({ files, onLoadData: props.onLoadData, setError })
          }}
          accept={['text/csv']}
          multiple={false}
        >
          <Group justify="center" gap="xl" style={{ pointerEvents: 'none' }}>
            <Dropzone.Accept>
              <IconUpload size={32} stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={32} stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconFileTypeCsv size={32} stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Arraste o arquivo aqui ou clique para selecionar o arquivo
              </Text>
              <Text size="sm" c="dimmed" inline mt={7}>
                O arquivo deve ser do tipo CSV e ter as colunas Código do produtor, Data de entrega, Período de entrega,
                SKU, Quantidade e Valor.
              </Text>
            </div>
          </Group>
        </Dropzone>
        {!!error && (
          <Alert mt="md" variant="light" color="red" title="Erro" icon={<IconX />}>
            {error}
          </Alert>
        )}
        {!!props.data?.length && (
          <Box
            mah={300}
            style={{
              overflowY: 'auto',
            }}
          >
            <Table mt="md">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Código do produtor</Table.Th>
                  <Table.Th>Data de entrega</Table.Th>
                  <Table.Th>Período de entrega</Table.Th>
                  <Table.Th>SKU</Table.Th>
                  <Table.Th>Quantidade</Table.Th>
                  <Table.Th>Valor</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {sanitizedSheetData.map((item) => {
                  const { message: errorMessage, errors } = generateErrorMessage(item)

                  return (
                    <Tooltip
                      key={item.sku.nameFromSheet + item.producer.codeFromSheet + item.deliveryDate.value}
                      label={errorMessage}
                      disabled={!errorMessage}
                      withArrow
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <Table.Tr bg={colorConditional(!!errorMessage, 'var(--mantine-color-red-light)')}>
                        <Table.Td c={colorConditional(errors.producer, 'red')}>
                          <span>{item.producer?.name ?? 'Não encontrado'}</span>
                        </Table.Td>
                        <Table.Td c={colorConditional(errors.deliveryDate, 'red')}>{item.deliveryDate.value}</Table.Td>
                        <Table.Td>{item.deliveryPeriod}</Table.Td>
                        <Table.Td c={colorConditional(errors.sku, 'red')}>
                          <span>{item.sku?.SKU ?? 'Não encontrado'}</span>
                        </Table.Td>
                        <Table.Td c={colorConditional(errors.desiredQty, 'red')}>{item.desiredQty}</Table.Td>
                        <Table.Td c={colorConditional(errors.price, 'red')}>{item.price}</Table.Td>
                      </Table.Tr>
                    </Tooltip>
                  )
                })}
              </Table.Tbody>
            </Table>
          </Box>
        )}
        <Flex justify="end" align="center" mt="xl" gap="sm">
          <Button onClick={closeModal} variant="outline" color="gray" c="black">
            Cancelar
          </Button>
          <Button
            disabled={!props.data?.length || shouldDisableButtons}
            onClick={() => handleConfirm(false)}
            color="yellow"
          >
            Deixar pendente
          </Button>
          <Button disabled={!props.data?.length || shouldDisableButtons} onClick={() => handleConfirm(true)}>
            Enviar pedidos
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

interface FileUploadProps {
  files: File[]
  onLoadData?: (data: OrderImport[]) => void
  setError: (error: string) => void
}

const handleFileUpload = ({ files, onLoadData, setError }: FileUploadProps) => {
  onLoadData?.([])
  const file = files[0]

  if (file) {
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result
      if (text && typeof text === 'string') {
        try {
          const parsedData = parseCSV(text)

          const sanitizedData = parsedData.map((data) => {
            const normalizedData = Object.keys(data).reduce(
              (acc, key) => {
                const normalizedKey = normalizeKey(key)
                acc[normalizedKey] = data[key]
                return acc
              },
              {} as Record<string, any>,
            )

            return {
              producerCode: String(normalizedData['codigo do produtor']) ?? 'Valor inválido',
              deliveryDate: normalizedData['data de entrega'] ?? 'Valor inválido',
              deliveryPeriod: normalizedData['periodo de entrega'] ?? 'Valor inválido',
              sku: String(normalizedData['sku']).toUpperCase() ?? 'Valor inválido',
              desiredQty: Number(normalizedData['quantidade'] ?? 'Valor inválido'),
              price: Number(normalizedData['valor'] ?? 'Valor inválido'),
            }
          })

          onLoadData?.(sanitizedData)
          setError('')
        } catch (error) {
          console.log(error)
          setError('Ocorreu um erro, garanta que seu arquivo não contém comentários e esteja na estrutura esperada.')
        }
      }
    }
    reader.readAsText(file)
  }
}

const parseCSV = (csvText: string): Record<string, string>[] => {
  return csvParse(csvText, { columns: true, skip_empty_lines: true, trim: true })
}

function normalizeKey(key: string): string {
  return key
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

function colorConditional(cond: boolean, color: string) {
  if (!cond) return

  return color
}

type SKUInfo = Partial<SKU> & {
  found: boolean
  nameFromSheet: string
  isRepeated: boolean
}

type ProducerInfo = Partial<Producer> & {
  found: boolean
  codeFromSheet: string
}

type DeliveryDateInfo = {
  value: string
  isValid: boolean
  isPast: boolean
}

type GenerateErrorMessageContext = {
  sku: SKUInfo
  producer: ProducerInfo
  price: number
  desiredQty: number
  deliveryDate: DeliveryDateInfo
}

function sanitizeSheetData(item: OrderImport, sku?: SKU, producer?: Producer, uniqueSkus?: Map<string, boolean>) {
  const parsedDeliveryDate = parse(item.deliveryDate, 'dd/MM/yyyy', new Date())

  const isValidDeliveryDate = isValid(parsedDeliveryDate)

  const isBeforeToday = isBefore(parsedDeliveryDate, startOfDay(new Date()))

  const key = JSON.stringify({
    sku: item.sku,
    producerCode: item.producerCode,
    deliveryDate: item.deliveryDate,
  });
  
  const isRepeated = uniqueSkus?.has(key);
  
  if (!isRepeated) {
    uniqueSkus?.set(key, true);
  }

  return {
    sku: {
      ...sku,
      found: !!sku,
      nameFromSheet: item.sku,
      isRepeated: !!isRepeated
    },
    producer: {
      ...producer,
      found: !!producer,
      codeFromSheet: item.producerCode,
    },
    desiredQty: item.desiredQty,
    price: item.price,
    deliveryDate: {
      value: item.deliveryDate,
      isValid: isValidDeliveryDate,
      isPast: isBeforeToday,
    },
    deliveryPeriod: item.deliveryPeriod,
  }
}

function generateErrorMessage(ctx: GenerateErrorMessageContext) {
  const { sku, producer, desiredQty, price, deliveryDate } = ctx

  const messagesArr: string[] = []

  const errors: Record<string, boolean> = {}

  if (!sku.found) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}SKU "${sku.nameFromSheet}" não encontrado.`)
    errors.sku = true
  }

  if (sku.isRepeated) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}SKU "${sku.nameFromSheet}" duplicado.`)
    errors.sku = true
  }

  if (!producer.found) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}Produtor "${producer.codeFromSheet}" não encontrado.`)
    errors.producer = true
  }

  if (!desiredQty) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}Quantidade não encontrada ou zero.`)
    errors.desiredQty = true
  }

  if (!price) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}Preço não encontrado ou zero.`)
    errors.price = true
  }

  if (!deliveryDate.isValid) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}Data inválida, forneça no formado dd/mm/aaaa.`)
    errors.deliveryDate = true
  }

  if (deliveryDate.isPast) {
    messagesArr.push(`${decideToAddNewLine(messagesArr)}Não é possível enviar um pedido para uma data no passado.`)
    errors.deliveryDate = true
  }

  return {
    errors,
    message: messagesArr.join(''),
  }
}

function decideToAddNewLine(arr: unknown[]) {
  return arr.length ? '\n\n' : ''
}
