import { useCallback, useMemo, useState } from 'react'
import { Card, Title, Button, Space, Center, Text, Flex, Loader } from '@mantine/core'
import { IconFileDownload } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useDistributor } from 'src/providers/Distributor'
import { CustomTable } from './Table'
import { useQuery } from '@tanstack/react-query'
import { ExportToCSVModal } from '../common/Modals/ExportModal'
import { sanitizeSentOrdersToCsv } from 'src/utils/prepareDataToCsvExport'
import { getSentOrders } from 'src/requests/orders/getSentOrders'
import { startOfDay } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { OrderFromBackend, Producer } from 'src/types'

const INITIAL_DATES_STATE = {
  startDate: null,
  endDate: null,
}

type ExportDates = {
  startDate: Date | null
  endDate: Date | null
}

export default function SentOrdersTable() {
  const [exportDates, setExportDates] = useState<ExportDates>(INITIAL_DATES_STATE)
  const [opened, { open, close }] = useDisclosure()
  const { distributor } = useDistributor()
  const [page, setPage] = useState(1)
  const { data, isLoading } = useQuery({
    queryKey: ['orders', 'sent', { page, size: 15 }],
    queryFn: () =>
      getSentOrders({
        distributorId: distributor!.distributorId,
        page,
        size: 15,
      }),
    enabled: !!distributor!.distributorId,
  })

  const ordersWithNames = useMemo(
    () => addProducerNameToOrders(data?.records, distributor?.producers),
    [data?.records, distributor?.producers],
  )

  const { data: filteredByDateOrders, isFetching: filteredByDateOrdersIsFetching } = useQuery({
    queryKey: ['orders', 'sent', { page }],
    queryFn: () =>
      getSentOrders({
        distributorId: distributor!.distributorId,
        page,
        startDate: startOfDay(new UTCDate(exportDates.startDate!)),
        endDate: startOfDay(new UTCDate(exportDates.endDate!)),
      }),
    enabled: !!distributor!.distributorId && !!exportDates.endDate && !!exportDates.startDate,
  })

  const filteredByDatesOrdersWithNames = useMemo(
    () => addProducerNameToOrders(filteredByDateOrders?.records, distributor?.producers),
    [filteredByDateOrders?.records, distributor?.producers],
  )

  const csvData = sanitizeSentOrdersToCsv(filteredByDatesOrdersWithNames, exportDates, distributor?.skus)

  const handleClose = useCallback(() => {
    setExportDates(INITIAL_DATES_STATE)
    close()
  }, [close])

  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={0}
      shadow="md"
      radius="md"
    >
      <ExportToCSVModal
        title="Exportar pedidos enviados"
        filenamePrefix="pedidos"
        exportDates={exportDates}
        setExportDates={setExportDates}
        opened={opened}
        onClose={handleClose}
        csvData={csvData}
        isLoading={filteredByDateOrdersIsFetching}
      />
      <Flex align="center" justify="space-between" px={25}>
        <Title order={3}>Pedidos enviados</Title>
        <Button
          onClick={open}
          size="sm"
          variant="default"
          radius="sm"
          leftSection={<IconFileDownload strokeWidth={1.5} size={18} />}
        >
          Exportar
        </Button>
      </Flex>
      <Space h="md" />
      {!data || !data?.records?.length || isLoading ? (
        <Center>
          <Text>{isLoading ? <Loader /> : 'Você ainda não tem pedidos enviados'}</Text>
        </Center>
      ) : (
        <CustomTable activePage={page} onChangePage={setPage} totalPages={data.last} items={ordersWithNames} />
      )}
    </Card>
  )
}

function addProducerNameToOrders(orders: OrderFromBackend[] = [], producers: Producer[] = []) {
  return orders.map((item) => {
    const producer = producers.find((p) => p.phone === item.phone)

    return {
      ...item,
      name: producer?.name ?? 'Produtor não encontrado',
    }
  })
}
