import { Table } from '@mantine/core'

export function GenerateOrderWithAITableHead() {
  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th pl={0}>Nome do produtor</Table.Th>
        <Table.Th ta="center">Taxa de devolução</Table.Th>
        <Table.Th ta="center">Preço</Table.Th>
        <Table.Th ta="center">Ofertado</Table.Th>
        <Table.Th ta="center" pr={0}>
          A pedir
        </Table.Th>
      </Table.Tr>
    </Table.Thead>
  )
}
