import { Image, Space, Stack, Button } from '@mantine/core'
import {
  IconBoxSeam,
  IconTractor,
  IconPencil,
  IconReceipt,
  IconPackageImport,
  IconCubePlus,
  IconChartLine,
} from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'
import type { NavbarContentProps } from 'src/types'
import styles from './Navbar.module.css'

type navLinkClassNameContext = {
  isActive: boolean
}

function navLinkClassName({ isActive }: navLinkClassNameContext) {
  return isActive ? styles.linkActive : styles.link
}

export default function NavbarContent(props: NavbarContentProps) {
  const { isMobile } = props
  return (
    <>
      {isMobile && <Image src="/logo.png" fit="contain" height={20} alt="logo" className={styles.logo} />}
      {isMobile && <Space h="lg" />}
      <Stack justify="space-between">
        <Stack gap="xs">
          <NavLink to="/produtores" className={navLinkClassName}>
            <Button leftSection={<IconTractor size={17} />} variant="white" fullWidth>
              Produtores
            </Button>
          </NavLink>
          <NavLink to="/ofertas/sku" className={navLinkClassName}>
            <Button leftSection={<IconBoxSeam size={17} />} variant="white" fullWidth>
              Ofertas
            </Button>
          </NavLink>
          <NavLink to="/ofertas/criar" className={navLinkClassName}>
            <Button leftSection={<IconCubePlus size={17} />} variant="white" fullWidth>
              Criar oferta
            </Button>
          </NavLink>
          <NavLink end to="/ofertas" className={navLinkClassName}>
            <Button leftSection={<IconPackageImport size={17} />} variant="white" fullWidth>
              Ofertas recebidas
            </Button>
          </NavLink>
          <NavLink to="/pedidos-abertos" className={navLinkClassName}>
            <Button leftSection={<IconPencil size={17} />} variant="white" fullWidth>
              Pedidos abertos
            </Button>
          </NavLink>
          <NavLink to="/pedidos-enviados" className={navLinkClassName}>
            <Button leftSection={<IconReceipt size={17} />} variant="white" fullWidth>
              Pedidos enviados
            </Button>
          </NavLink>
          <NavLink to="/relatorios" className={navLinkClassName}>
            <Button leftSection={<IconChartLine size={17} />} variant="white" fullWidth>
              Relatórios
            </Button>
          </NavLink>
        </Stack>
      </Stack>
    </>
  )
}
