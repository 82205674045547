import { Table } from '@mantine/core'

export function CustomTableHead() {
  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Data de entrega</Table.Th>
        <Table.Th>Data da oferta </Table.Th>
        <Table.Th>Comprador</Table.Th>
        <Table.Th>Produtor</Table.Th>
        <Table.Th>Telefone</Table.Th>
        <Table.Th>SKU</Table.Th>
        <Table.Th>Volume</Table.Th>
        <Table.Th>Valor</Table.Th>
        <Table.Th></Table.Th>
      </Table.Tr>
    </Table.Thead>
  )
}
