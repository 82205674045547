import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import formatPrice from './formatPrice'
import type { Distributor, OrderFromBackend, ReceivedOffer } from 'src/types'
import { formatDate, formatUTCDate } from './formatDate'
import { endOfDay, isWithinInterval, startOfDay } from 'date-fns'

import { UTCDate } from '@date-fns/utc'

const sentOrdersHeader = [
  'Identificador',
  'Data de entrega',
  'Data do pedido',
  'Comprador',
  'Produtor',
  'Telefone',
  'SKU',
  'Categoria',
  'Medida',
  'Valor (por medida)',
  'Quantidade',
  'Valor total',
]

type exportDates = {
  startDate: Date | null
  endDate: Date | null
}

type OrdersWithProducerName = OrderFromBackend & {
  name: string
}

export function sanitizeSentOrdersToCsv(
  ordersWithProducerName: OrdersWithProducerName[],
  exportDates: exportDates,
  skus: Distributor['skus'] = [],
) {
  if (!exportDates.startDate || !exportDates.endDate) {
    return []
  }
  const dataBodyToExportCsv = ordersWithProducerName.flatMap((order) => {
    const id = order.id
    const date = order.sentAt ? formatDate(order.sentAt, 'dd/MM/yyyy') : ''
    const deliveryDate = order.deliveryDate ? formatUTCDate(order.deliveryDate, 'dd/MM/yyyy') : ''
    const buyerName = order.buyer?.name ?? 'Não informado'
    const producerName = order.name
    const producerPhone = formatBrazilianNumber(order.phone).slice(3)
    const skusInDraftOrder = order.sentOrder.map((orderItem) => {
      const sku = orderItem.sku
      const pricePerUnit = formatPrice(orderItem.price)
      const category = skus.find((s) => s['SKU'] === sku)?.['Categoria'] ?? 'Não informado'
      const unit = skus.find((s) => s['SKU'] === sku)?.['Medida'] ?? ''
      const amount = orderItem.desiredQty

      const total = formatPrice(orderItem.price * Number(orderItem.desiredQty))

      return [
        id,
        deliveryDate,
        date,
        buyerName,
        producerName,
        producerPhone,
        sku,
        category,
        unit,
        pricePerUnit,
        amount,
        total,
      ]
    })
    return skusInDraftOrder
  })

  const csvData = [sentOrdersHeader, ...dataBodyToExportCsv]
  return csvData
}

const receivedOffersHeader = [
  'Identificador',
  'Data de entrega',
  'Data da oferta',
  'Solicitante',
  'Produtor',
  'Telefone',
  'SKU',
  'Categoria',
  'Medida',
  'Valor (por medida)',
  'Quantidade',
  'Valor total',
]

export function sanitizeReceivedOffersToCsv(offers: ReceivedOffer[], exportDates: exportDates) {
  if (!exportDates.startDate || !exportDates.endDate) {
    return []
  }

  const filteredOffers = offers.filter((offer) => {
    const startOfDayStartDate = startOfDay(new UTCDate(exportDates.startDate!))
    const endOfDayEndDate = endOfDay(new UTCDate(exportDates.endDate!))

    const isInRange = isWithinInterval(offer.deliveryDate, {
      start: startOfDayStartDate,
      end: endOfDayEndDate,
    })

    return isInRange
  })

  const dataBodyToExportCsv = filteredOffers.map((offer) => {
    const id = offer.id
    const date = offer.date
    const deliveryDate = offer.deliveryDate ? formatUTCDate(offer.deliveryDate, 'dd/MM/yyyy') : ''
    const requesterName = offer.requester?.name ?? 'Não informado'
    const producerName = offer.producer.name
    const producerPhone = offer.producer.phone
    const category = offer.category ?? 'Não informado'
    const sku = offer.sku
    const pricePerUnit = offer.price
    const volume = offer.volume.replace(/\D/g, '')
    const unit = offer.unit
    const total = formatPrice(parseFloat(offer.price.replace(',', '.')) * Number(volume))

    return [
      id,
      deliveryDate,
      date,
      requesterName,
      producerName,
      producerPhone,
      sku,
      category,
      unit,
      pricePerUnit,
      volume,
      total,
    ]
  })

  return [receivedOffersHeader, ...dataBodyToExportCsv]
}
