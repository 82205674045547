import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import InitialLoader from 'src/components/InitialLoader'
import RegisterOrganization from 'src/components/RegisterOrganization'
import AppShell from 'src/components/AppShell'
import Producers from 'src/pages/Producers'
import SKUSupply from 'src/pages/SKUSupply'
import DraftOrders from 'src/pages/DraftOrders'
import SentOrders from 'src/pages/SentOrders'
import CreateTeam from 'src/components/CreateTeam'
import NotFound from 'src/pages/NotFound'
import Messages from 'src/pages/Messages'
import { useFirebaseAuth } from 'src/providers/FirebaseAuth'
import { useOrders } from 'src/providers/Orders'
import Smartlook from 'smartlook-client'
import OffersPagesRoute from './pages/Offers'
import MarketReportsPage from './pages/MarketReports'
import OneSignal from 'react-onesignal'
import { useDistributor } from './providers/Distributor'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'

if (import.meta.env.VITE_SMARTLOOK_KEY && import.meta.env.VITE_SMARTLOOK_REGION) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY as string, {
    region: import.meta.env.VITE_SMARTLOOK_REGION,
  })
}

export default function App() {
  const { isAuthenticating } = useFirebaseAuth()
  const { user } = useKindeAuth()
  const { isLoading: isLoadingOrders } = useOrders()
  const isFetching = isAuthenticating || isLoadingOrders
  const { distributor } = useDistributor()
  const [isOneSignalInitialized, setOneSignalInitialized] = useState(false)

  function runOneSignal() {
    if (isOneSignalInitialized) return

    OneSignal.init({ appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID })
      .then(() => {
        setOneSignalInitialized(true)
        console.log('OS-st')
        OneSignal.Slidedown.promptPush()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        console.log('OS-st-f')
      })
  }

  useEffect(() => {
    runOneSignal()
    return () => {
      setOneSignalInitialized(false)
    }
  }, [])

  useEffect(() => {
    if (user?.id && OneSignal) {
      OneSignal.login(user.id)
      console.log('OS-lg-f')
    }
  }, [isOneSignalInitialized, user?.id])

  useEffect(() => {
    if (distributor?.distributorId && OneSignal) {
      console.log('OS-tg')
      const tags: Record<string, string> = {}
      tags['distributor_id'] = distributor.distributorId
      OneSignal.User.addTags(tags)
      console.log('OS-tg-f')
    }
  }, [isOneSignalInitialized, distributor?.distributorId])

  if (isFetching) return <InitialLoader />
  return (
    <Routes>
      <Route path="/register-org" element={<RegisterOrganization />} />
      <Route path="/mensagens/*" element={<Messages />} />
      <Route element={<AppShell />}>
        <Route index element={<Producers />} />
        <Route path="produtores/*" element={<Producers />} />
        <Route path="ofertas/*" element={<OffersPagesRoute />} />
        <Route path="ofertas/sku/*" element={<SKUSupply />} />
        <Route path="pedidos-abertos/*" element={<DraftOrders />} />
        <Route path="pedidos-enviados/*" element={<SentOrders />} />
        <Route path="create-team" element={<CreateTeam />} />
        <Route path="relatorios" element={<MarketReportsPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
