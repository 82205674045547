import { Button, Checkbox, Menu, Table } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'
import { useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link, useNavigate } from 'react-router-dom'
import DeleteModal from 'src/components/common/Modals/DeleteModal'
import { pendingNotificationFactory } from 'src/factories/pendingNotificationFactory'
import { IsCheckboxChecked, OnIndividualCheckboxChange } from 'src/hooks/useBulkCheckbox'
import { useProducers } from 'src/hooks/useProducers'
import { useDistributor } from 'src/providers/Distributor'
import { deleteProducerAndFixedPrices } from 'src/requests/firebase/producers'
import type { Producer } from 'src/types'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { formatDateDistanceFromNow } from 'src/utils/formatDate'

export type ProducerTableBodyProps = {
  items: Producer[]
  currentSendingProducerPhone: string
  successSentProducersPhones: string[]
  sendMessage: (phone: string) => Promise<void>
  isCheckboxChecked: IsCheckboxChecked
  onIndividualCheckboxChange: OnIndividualCheckboxChange
}

export function CustomTableBody(props: ProducerTableBodyProps) {
  const { distributor } = useDistributor()
  const { producers } = useProducers()
  const { showBoundary } = useErrorBoundary()

  const [isModalOpen, { open: openModal, close: closeModal }] = useDisclosure(false)
  const [producerToDelete, setProducerToDelete] = useState<Producer>()
  const navigate = useNavigate()

  const sendIndividualMessage = (phone: string) => async () => {
    await props.sendMessage(phone)
  }

  async function handleDeleteProducer() {
    const notification = pendingNotificationFactory({
      pending: {
        title: 'Removendo produtor...',
        message: 'Isso não deve demorar muito',
      },
      success: {
        title: 'Produtor removido com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
      },
      error: {
        title: 'Erro ao remover Produtor.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
      },
    })

    try {
      const producer = producers.find((p) => p.code === producerToDelete?.code)

      if (!producer) return

      await deleteProducerAndFixedPrices({
        ...producer,
        distributorId: distributor!.distributorId,
      })
      notification.success()
      closeModal()
      setProducerToDelete(undefined)
    } catch (error) {
      notification.error()
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error.message)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  const onEdit = (phone: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    navigate(`/produtores/editar/${phone}`)
  }

  const onDelete = (producer: Producer) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (producer) {
      setProducerToDelete(producer)
      openModal()
    }
  }

  function onCloseModal() {
    setProducerToDelete(undefined)
    closeModal()
  }

  return (
    <>
      <DeleteModal
        show={isModalOpen}
        title="Remover produtor"
        description="Tem certeza que deseja remover esse produtor?"
        onCloseModal={onCloseModal}
        handleDelete={handleDeleteProducer}
      />
      <Table.Tbody>
        {props.items.map((producer) => {
          const name = producer.name
          const phoneToDisplay = formatBrazilianNumber(producer.phone).slice(3)
          const producerPhone = producer.phone

          const rowState = {
            sent: props.successSentProducersPhones.some((phone) => phone === producerPhone),
            loading: props.currentSendingProducerPhone === producerPhone,
          }

          return (
            <Table.Tr key={name}>
              <Table.Td>
                <Checkbox
                  aria-label="Select row"
                  defaultValue={producer.phone}
                  checked={props.isCheckboxChecked(producer.phone)}
                  onChange={props.onIndividualCheckboxChange}
                />
              </Table.Td>
              <Table.Td>
                <Button p="0" component={Link} to={`/mensagens/${producer.phone}`} variant="transparent">
                  {name}
                </Button>
              </Table.Td>
              <Table.Td>{phoneToDisplay}</Table.Td>
              <Table.Td align="center">
                {producer?.lastRequest ? formatDateDistanceFromNow(producer?.lastRequest?.toDate()) : '*'}
              </Table.Td>
              <Table.Td align="center">
                {producer?.lastOffer ? formatDateDistanceFromNow(producer?.lastOffer?.toDate()) : '*'}
              </Table.Td>
              <Table.Td align="right">
                <Button
                  onClick={sendIndividualMessage(producerPhone)}
                  disabled={rowState.sent}
                  loading={rowState.loading}
                  loaderProps={{ type: 'dots' }}
                >
                  {rowState.sent ? 'Solicitado!' : 'Solicitar oferta'}
                </Button>
              </Table.Td>
              <Table.Td align="center">
                <Menu shadow="md" withArrow position="bottom-end">
                  <Menu.Target>
                    <Button onClick={(e) => e.stopPropagation()} variant="subtle" size="compact-sm">
                      <IconDotsVertical size={20} />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item onClick={onEdit(producer.phone)} leftSection={<IconPencil size={14} />}>
                      Editar
                    </Menu.Item>
                    <Menu.Item onClick={onDelete(producer)} color="red" leftSection={<IconTrash size={14} />}>
                      Remover
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </>
  )
}
