import ProducerForm from './ProducerForm'
import { TCreateFixedPrices, TCreateOrUpdateProducerForm } from 'src/types'
import { useNavigate, useParams } from 'react-router-dom'
import { useDistributor } from 'src/providers/Distributor'
import { useErrorBoundary } from 'react-error-boundary'
import { ActionIcon, Box, Card, Center, Space, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconArrowNarrowLeft, IconCheck, IconX } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { createProducerAndFixedPrices, updateProducerAndFixedPrices } from 'src/requests/firebase/producers'
import { useMemo } from 'react'
import { keepFirstArrayItem, removeDuplicatedStrings } from 'src/utils/arrays/removeDuplicated'

const CreateOrUpdateProducer = () => {
  const { phone } = useParams()
  const navigate = useNavigate()
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()

  const currentProducer = useMemo(() => {
    return distributor?.producers.find((producer) => producer.phone === phone)
  }, [distributor?.producers, phone])

  const producerFixedPrices = useMemo(() => {
    return (
      distributor?.fixedPrices
        .filter((fixedPrice) => fixedPrice.producerCode === currentProducer?.code)
        .filter((fixedPrice) => distributor.skus.some((sku) => sku['Código'] === fixedPrice.sku))
        .map(({ fixedPrice, sku }) => ({
          fixedPrice,
          sku,
        })) ?? []
    )
  }, [currentProducer?.code, distributor?.fixedPrices, distributor?.skus])

  const currentProducerSkuCodesRefence = useMemo(() => {
    return (
      currentProducer?.skuCodesReference?.filter((code) => distributor?.skus.some((sku) => sku['Código'] === code)) ??
      []
    )
  }, [currentProducer?.skuCodesReference, distributor?.skus])

  const defaultValues = useMemo(() => {
    if (currentProducer) {
      const fixedPricesFromSkuCodesReference = currentProducerSkuCodesRefence.map((sku) => ({
        sku,
        fixedPrice: 0,
      }))

      const skuCodesReferenceFromFixedPrices = producerFixedPrices.map((item) => item.sku)

      const fixedPrices = keepFirstArrayItem(producerFixedPrices, fixedPricesFromSkuCodesReference, 'sku')

      const skuCodesReference = removeDuplicatedStrings([
        ...skuCodesReferenceFromFixedPrices,
        ...currentProducerSkuCodesRefence,
      ])

      return {
        ...currentProducer,
        skuCodesReference,
        fixedPrices,
      }
    }
  }, [currentProducer, producerFixedPrices, currentProducerSkuCodesRefence])

  const mutation = useMutation({
    mutationFn: phone ? updateProducerAndFixedPrices : createProducerAndFixedPrices,
  })

  const handleSubmit = async (data: TCreateOrUpdateProducerForm) => {
    const fixedPricesWithProducerData: TCreateFixedPrices[] = data.fixedPrices.map((fixedPrice) => ({
      ...fixedPrice,
      fixedPrice: fixedPrice.fixedPrice,
      producerName: data.name,
      producerCode: data.code,
    }))

    const notificationId = notifications.show({
      loading: true,
      title: 'Salvando seus dados...',
      message: 'Isso não deve demorar muito',
      autoClose: false,
      withCloseButton: false,
    })

    try {
      await mutation.mutateAsync({
        ...data,
        distributorId: distributor!.distributorId,
        fixedPrices: fixedPricesWithProducerData,
      })
      notifications.update({
        id: notificationId,
        color: 'teal',
        title: 'Dados salvos com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
        icon: <IconCheck size={18} />,
        loading: false,
        autoClose: 4000,
        withCloseButton: true,
      })
      navigate('..')
    } catch (error) {
      notifications.update({
        id: notificationId,
        color: 'red',
        title: 'Erro ao salvar os dados.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
        icon: <IconX size={18} />,
        loading: false,
        withCloseButton: true,
        autoClose: 4000,
      })
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error.message)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  return (
    <Card
      padding="xl"
      shadow="md"
      radius="md"
      style={{
        overflow: 'visible',
      }}
    >
      <Box display="flex" mb="lg">
        <Center>
          <ActionIcon
            style={{
              border: 0,
            }}
            onClick={() => navigate(-1)}
            size={24}
            color="dark"
            variant="transparent"
            p={0}
            w="fit-content"
          >
            <IconArrowNarrowLeft size={24} />
          </ActionIcon>
          <Space w="sm" />
          <Text fw={500}>{phone ? `Alterar ${currentProducer?.name}` : 'Cadastrar produtor'}</Text>
        </Center>
      </Box>
      <ProducerForm onSubmit={handleSubmit} defaultValues={defaultValues} />
    </Card>
  )
}

export default CreateOrUpdateProducer
